import React, { useEffect, useState } from "react";
import _ from "lodash";
import classNames from "classnames";
import ServicesPage from "./ServicesPage";
import PortfolioPage from "./PortfolioPage";
import AboutPage from "./AboutPage";
import ContactPage from "./ContactPage";
import { importAllImages } from "../utils/images";
import { makeStyles } from "@material-ui/core";
import AOS from "aos";
import Footer from "../components/Footer";
import { ReactComponent as TyKargesLogo } from "../media/tykargesLogo.svg";
import ArrowBouncer from "../components/ArrowBouncer";
import SwipeableTextMobileStepper from "../components/SwipeableTextMobileStepper";
import { WhiteDiv } from "../components/WhiteDiv";
import contactPageImage1 from "../media/contact/contactPage1.png";
import TestimonialSection from "../components/TestimonialSection";
// import TestimonialSection from "../components/TestimonialSection";

const images = _.sortBy(
  importAllImages(
    require.context("../media/banner", false, /\.(png|jpe?g|svg)$/)
  ),
  (filename) => {
    return +(
      filename.match(/\/(?<idNumStr>\d+)/)?.groups?.idNumStr ?? Infinity
    );
  }
);
const steps = images.map((image) => ({ image, label: "" }));

const ORIGINAL_WINDOW_ON_SCROLL = window.onscroll;

const useStyles = makeStyles((theme) => ({
  totalContainer: {
    maxWidth: "100vw",
  },
  stretchContainer: {
    height: "calc(100vh - 106px)", // To not show anything other than the blur stepper before the user scrolls (106px is the header height)
    display: "flex",
    flexDirection: "column",
  },
  fakeFooterContainer: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center",
    "& > div": {
      flexGrow: 0,
      borderTop: "none",
    },
  },
  logo: {
    height: "54px",
  },
  contactBackgroundImage: {
    position: "fixed",
    background: `url(${contactPageImage1}) no-repeat center center / cover`,
    // width: '100%',
    // height: 'calc(100% + 106px)',
    top: 106,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
    return () => {
      window.onscroll = ORIGINAL_WINDOW_ON_SCROLL;
    };
  }, []);

  const atTop = offset === 0;
  useEffect(() => {
    AOS.refreshHard();
  }, [atTop]);

  return (
    <div className={classes.totalContainer}>
      <div className={classes.contactBackgroundImage} />
      <WhiteDiv>
        <div className={classNames({ [classes.stretchContainer]: atTop })}>
          <SwipeableTextMobileStepper
            steps={steps}
            autoPlay
            maxImageHeight={`100vh - 106px`}
          />
          {atTop && (
            <div className={classes.fakeFooterContainer}>
              <TyKargesLogo className={classes.logo} />
              <Footer />
              <ArrowBouncer />
            </div>
          )}
        </div>
        <div data-aos="fade-up">
          <ServicesPage />
        </div>
        <div data-aos="fade-up">
          <PortfolioPage />
        </div>
        <div data-aos="fade-up">
          <TestimonialSection />
        </div>
        <div data-aos="fade-up">
          <AboutPage />
        </div>
      </WhiteDiv>
      <div data-aos="fade-up">
        <ContactPage />
      </div>
    </div>
  );
};

export default HomePage;
